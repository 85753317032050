import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import QuillEditor from '../QuillEditor.tsx';

const ProfileViewEdit = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [imageUrl, setImageUrl] = useState('');

  const [formData, setFormData] = useState({
    user_id: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    category: [],
    areaOfOperation: [],
    experience: '',
    proprietorship: '',
    email: '',
    phone: '',
    country: null,
    state: null,
    city: null,
    address: '',
    info: '',
    coverDescription: '',
    imageUrl: '',
  });

  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  const user_id = storedUser ? storedUser.id : null;

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userResponse = await axios.get(
          `https://www.astsoftwaresolutions.in/api/users/getUsers/${user_id}`,
          { headers: { 'Content-Type': 'application/json' } }
        );

        const userData = userResponse.data.data[0];
        console.log(userData, 'userData profile');

        setFormData({
          user_id: userData.user_id || '',
          firstName: userData.firstname || '',
          lastName: userData.lastname || '',
          dateOfBirth: userData.date_of_birth || '',
          gender: userData.gender || '',
          category: userData.category
            ? userData.category.map((cat) => ({ value: cat, label: cat }))
            : [],
          areaOfOperation: userData.area_of_operation
            ? userData.area_of_operation.map((area) => ({ value: area, label: area }))
            : [],
          experience: userData.experience_years || '',
          proprietorship: userData.proprietorship || '',
          email: userData.email || '',
          phone: userData.phone_number || '',
          country: userData.country
            ? { value: userData.country, label: `Country ${userData.country}` }
            : null,
          state: userData.state
            ? { value: userData.state, label: `State ${userData.state}` }
            : null,
          city: userData.city ? { value: userData.city, label: `City ${userData.city}` } : null,
          address: userData.address || '',
          info: userData.info?.trim() !== '' ? userData.info : null,
          coverDescription: userData.cover_description?.trim() !== '' ? userData.cover_description : null,
          imageUrl: userData.profile_pic_url?.trim() !== '' ? userData.profile_pic_url : null,
        });

        setImageUrl(userData.profile_pic_url?.trim() !== '' ? userData.profile_pic_url : null);

        // Fetch categories, countries, etc.
        const formDataToSend = { webType: '1' };
        const categoriesResponse = await axios.post(
          'https://www.astsoftwaresolutions.in/api/products/categoryList',
          formDataToSend
        );
        setCategories(
          categoriesResponse.data.map((cat) => ({ value: cat.id, label: cat.name }))
        );

        const countriesResponse = await axios.get(
          'https://www.astsoftwaresolutions.in/api/products/countries'
        );
        setCountries(
          countriesResponse.data.map((country) => ({ value: country.id, label: country.name }))
        );

        const areasResponse = await axios.get('https://www.astsoftwaresolutions.in/api/products/cities');
        setAreas(
          areasResponse.data.map((area) => ({ value: area.id, label: area.name }))
        );

        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setLoading(false);
      }
    };

    if (user_id) {
      fetchUserProfile();
    }
  }, [user_id]);

  // Fetch states when a country is selected
  useEffect(() => {
    if (formData.country) {
      axios
        .get(`https://www.astsoftwaresolutions.in/api/products/states?countryId=${formData.country.value}`)
        .then((response) =>
          setStates(response.data.map((state) => ({ value: state.id, label: state.name })))
        )
        .catch((error) => console.error('Error fetching states:', error));
    }
  }, [formData.country]);

  // Fetch cities when a state is selected
  useEffect(() => {
    if (formData.state) {
      axios
        .get(`https://www.astsoftwaresolutions.in/api/products/cities?stateId=${formData.state.value}`)
        .then((response) =>
          setCities(response.data.map((city) => ({ value: city.id, label: city.name })))
        )
        .catch((error) => console.error('Error fetching cities:', error));
    }
  }, [formData.state]);

  // Handle input changes
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Handle file selection
  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      const fileData = new FormData();
      fileData.append('file', selectedFile);

      try {
        const response = await axios.post(
          'https://www.astsoftwaresolutions.in/api/image/uploadimages',
          fileData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        setImageUrl(response.data.fileUrl);
        handleInputChange('imageUrl', response.data.fileUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  // Submit form data
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const payload = {
        ...formData,
        category: formData.category.map((cat) => cat.value),
        areaOfOperation: formData.areaOfOperation.map((area) => area.value),
        country: formData.country ? formData.country.value : null,
        state: formData.state ? formData.state.value : null,
        city: formData.city ? formData.city.value : null,
        imageUrl: formData.imageUrl?.trim() !== '' ? formData.imageUrl : null,
        info: formData.info?.trim() !== '' ? formData.info : null,
        coverDescription: formData.coverDescription?.trim() !== '' ? formData.coverDescription : null,
      };

      console.log('Submitting Payload:', payload);

      const response = await axios.post(
        'https://www.astsoftwaresolutions.in/api/users/updateprofile',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log('Form submitted successfully:', response.data);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the form.');
    }
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <div className="loaderNew"></div>
      </div>
    );

  return (
    <div className='max-1140 mx-auto my-5'>
      <div className="tab-white-block">
      <div className='tab-white-block-header'>
        <div className='row g-2 align-items-center'>
            <div className='col text-start'>
              <h2 className='text-start mb-0 font-22 font-500'>Personal Info</h2>
            </div>
            
        </div>
      </div>

      <div className='tab-white-block-body'>
        
        
      <form className="" name="user_form" id="user_form" onSubmit={handleSubmit}>
      {/* Hidden User ID */}
      <input type="hidden" name="user_id" value={formData.user_id} />
  <div className="row g-4 align-items-center">
    {/* First Name */}
    <div className="col-md-6">
      <label className="form-label">First Name</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Name*"
        value={formData.firstName}
        onChange={(e) => handleInputChange('firstName', e.target.value)}
      />
    </div>

    {/* Last Name */}
    <div className="col-md-6">
      <label className="form-label">Last Name</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Name*"
        value={formData.lastName}
        onChange={(e) => handleInputChange('lastName', e.target.value)}
      />
    </div>

    {/* Date of Birth */}
    <div className="col-md-6">
      <label className="form-label">Date of Birth</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Date of Birth"
        value={formData.dateOfBirth}
        onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
      />
    </div>

    {/* Gender */}
    <div className="col-md-6">
      <label className="form-label">Gender</label>
      <div className="row g-3">
        <div className="col-auto">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="Gender"
              id="Male"
              checked={formData.gender === 'Male'}
              onChange={() => handleInputChange('gender', 'Male')}
            />
            <label className="form-check-label" htmlFor="Male">
              Male
            </label>
          </div>
        </div>
        <div className="col-auto">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="Gender"
              id="Female"
              checked={formData.gender === 'Female'}
              onChange={() => handleInputChange('gender', 'Female')}
            />
            <label className="form-check-label" htmlFor="Female">
              Female
            </label>
          </div>
        </div>
      </div>
    </div>

    {/* Category Select */}
    <div className="col-md-6">
      <label className="form-label">Select Category</label>
      <Select options={categories} value={formData.category} onChange={(selected) => handleInputChange('category', selected)} isMulti />
      {/* <Select
        isMulti
        options={categories.map((category) => ({
          value: category.id,
          label: category.name,
        }))}
        value={formData.category}
        onChange={(selected) => handleInputChange('category', selected)}
        placeholder="Select Categories"
      /> */}
    </div>

    {/* Area of Operation */}
    <div className="col-md-6">
      <label className="form-label">Area of Operation</label>
      <Select options={areas} value={formData.areaOfOperation} onChange={(selected) => handleInputChange('areaOfOperation', selected)} isMulti />
      {/* <Select
        isMulti
        options={areas.map((city) => ({
          value: city.id,
          label: city.name,
        }))}
        value={formData.areaOfOperation}
        onChange={(selected) => handleInputChange('areaOfOperation', selected)}
        placeholder="Select Area of Operation"
      /> */}
    </div>

    {/* Experience */}
    <div className="col-md-6">
      <label className="form-label">Experience</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Experience"
        value={formData.experience}
        onChange={(e) => handleInputChange('experience', e.target.value)}
      />
    </div>

    {/* Proprietorship */}
    <div className="col-md-6">
      <label className="form-label">Proprietorship</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Proprietorship"
        value={formData.proprietorship}
        onChange={(e) => handleInputChange('proprietorship', e.target.value)}
      />
    </div>

    {/* Email */}
    <div className="col-md-6">
      <label className="form-label">Email address</label>
      <input
        type="email"
        className="form-control height-50"
        placeholder="Email address"
        value={formData.email}
        onChange={(e) => handleInputChange('email', e.target.value)}
      />
    </div>

    {/* Phone */}
    <div className="col-md-6">
      <label className="form-label">Your Phone</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Contact Number"
        value={formData.phone}
        onChange={(e) => handleInputChange('phone', e.target.value)}
      />
    </div>

    {/* Country Select */}
    <div className="col-md-6">
      <label className="form-label">Country</label>
      <Select options={countries} value={formData.country} onChange={(selected) => handleInputChange('country', selected)} />
      {/* <Select
        options={countries.map((country) => ({
          value: country.id,
          label: country.name,
        }))}
        value={formData.country}
        onChange={(selected) => handleInputChange('country', selected)}
        placeholder="Select a Country"
      /> */}
    </div>

    {/* State Select */}
    <div className="col-md-6">
      <label className="form-label">State</label>
      <Select options={states} value={formData.state} onChange={(selected) => handleInputChange('state', selected)} />
      {/* <Select
        options={states.map((state) => ({
          value: state.id,
          label: state.name,
        }))}
        value={formData.state}
        onChange={(selected) => handleInputChange('state', selected)}
        placeholder="Select a State"
        isDisabled={!formData.country}
      /> */}
    </div>

    {/* City Select */}
    <div className="col-md-6">
      <label className="form-label">City</label>
      <Select options={cities} value={formData.city} onChange={(selected) => handleInputChange('city', selected)} />
      {/* <Select
        options={cities.map((city) => ({
          value: city.id,
          label: city.name,
        }))}
        value={formData.city}
        onChange={(selected) => handleInputChange('city', selected)}
        placeholder="Select a City"
        isDisabled={!formData.state}
      /> */}
    </div>

    {/* Address */}
    <div className="col-md-6">
      <label className="form-label">Address</label>
      <input
        type="text"
        className="form-control height-50"
        placeholder="Enter Location"
        value={formData.address}
        onChange={(e) => handleInputChange('address', e.target.value)}
      />
    </div>

    {/* Info (QuillEditor) */}
    <div className="col-12">
      <label className="form-label">Info</label>
      <QuillEditor
        value={formData.info}
        onChange={(value) => handleInputChange('info', value)}
      />
    </div>

    {/* Cover Description (QuillEditor) */}
    <div className="col-12">
      <label className="form-label">Cover Description</label>
      <QuillEditor
        value={formData.coverDescription}
        onChange={(value) => handleInputChange('coverDescription', value)}
      />
    </div>

    {/* Upload Images */}
    <div className="col-12">
      <label className="form-label">Upload Images</label>
      <div className="uploadwrap">
        <input type="file" onChange={handleFileChange} />
        <div className="d-flex align-items-center justify-content-center">
          <i className="bi bi-cloud-arrow-up-fill me-2"></i>
          <span>Drag and Drop Images here</span>
        </div>
        {imageUrl && (
          <img src={imageUrl} alt="Uploaded Preview" style={{ marginTop: '10px', maxWidth: '200px' }} />
        )}
      </div>
    </div>

    {/* Submit and Cancel Buttons */}
    <div className="col-md-12">
      <div className="row align-items-center">
        <div className="col-auto">
          <button type="submit" className="btn btn-submit radius-25">
            Submit
          </button>
        </div>
        <div className="col-auto">
          <button type="button" className="btn btn-cancel" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
      </div>
   

      

 
    </div>
    </div>
    
  );
};

export default ProfileViewEdit;

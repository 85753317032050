import React from 'react';
import AgentLeadForm from './AgentLeadForm.tsx';
import VerifyPopUp from '../VerifyPopUp.js';
import ImgMale from '../../images/yellow-student-boy.svg';
import { useNavigate } from 'react-router-dom';



function AgentDetail({ verify, verifyEmail, name, location, experience, properties, imageUrl, coverDescription }) {

  // Inside your component
const navigate = useNavigate();
const storedUser = JSON.parse(sessionStorage.getItem('user'));
const userId = storedUser ? storedUser.id : null;

  return (
      <>
          <div className="max-1140 mx-auto">
          <div className="row g-3 justify-content-end">
          <div className="col-auto">
          <button
            type="button"
            className="btn btn-green px-4 radius-25"
            onClick={() => navigate(`/editprofile/${userId}`)}
          >
           <i className="bi bi-pencil-square me-2"></i> Edit
          </button>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-green px-4 radius-25"
            onClick={() => navigate(`/changepassword/${userId}`)}
          >
            Change Password
          </button>
        </div>
          </div>
          
              <div className="row g-5">
                  <div className="col-md-auto">
                      <div
                          className="agentimg mb-3"
                          style={{
                              backgroundImage:
                                  `url(${imageUrl || ImgMale})`,
                          }}
                      ></div>
                      <h2 className="text-start font-32 font-600">{name || 'Agent Name'}</h2>
                      <div className="userType pro mb-3">
                          EXPERT <span>PRO</span>
                      </div>

                      <div className="alumni_profile_class_college">
                          <div className="alumni_profile_college_phone mb-1">
                              <span className="font-16 font-500 text-black line-height-18">{verify}</span>
                              <VerifyPopUp 
                                  className="verifyLink" 
                                  verify={verify} 
                                  name="Verify Now" 
                                  title="mobile number" 
                                  showIcon 
                                  edit="Number" 
                              />
                          </div>
                          <div className="alumni_profile_college_phone">
                              <span className="font-16 font-500 text-black line-height-18">{verifyEmail}</span>
                              <VerifyPopUp 
                                  className="verifiedLink" 
                                  verify={verifyEmail} 
                                  name="Verified" 
                                  title="Email Address" 
                                  showIcon 
                                  edit="Email Address" 
                              />
                          </div>
                      </div>
                  </div>
                  
                  <div className="col-md">
                      <h3 className="text-start font-24 font-500">About {name || 'Spectrum Realties'}</h3>
                      <p className="text-start font-18 mb-3">
                          {coverDescription || 'N/A'}
                      </p>

                      <div className="mb-3">
                          <h3 className="text-start font-24 font-500">Areas of Operation</h3>
                          <ul className="areas">
                              <li>{location || 'Unknown Location'}</li>
                              {/* Add more locations if available */}
                          </ul>
                      </div>

                      <div className="mb-3">
                          <div className="row g-4">
                              <div className="col-md-4">
                                  <div className="text-dark">{experience || 'N/A'}</div>
                                  <div className="text-gray-8b font-500">Experience</div>
                              </div>
                              <div className="col-md-4">
                                  <div className="text-dark">{properties || 'N/A'}</div>
                                  <div className="text-gray-8b font-500">Images</div>
                              </div>
                              <div className="col-md-4">
                                  <div className="text-dark">{properties || 'N/A'}</div>
                                  <div className="text-gray-8b font-500">Videos</div>
                              </div>
                          </div>
                      </div>
                      {/* Additional details */}
                  </div>
              </div>
          </div>
      </>
  );
}

export default AgentDetail;

import React from 'react';
import { SemiCircleProgress } from 'react-semicircle-progressbar';

function Subscription() {
  return (
    <>
      <div className='offerWrap'>
            <div className='offerleftlist'>
                <div className='top-detail'>

                </div>
                <div className='offerList'>
                    <ul>
                        <li><i className="bi bi-calendar"></i><span>Plan Validity</span></li>
                        <li><i className="bi bi-buildings-fill"></i><span>Position of your profiles in search result</span></li>
                        <li><i className="bi bi-people-fill"></i><span>Matching stars profiles</span></li>
                        <li><i className="bi bi-headset"></i><span>Relationship Manager Assistance</span></li>
                        <li><i className="bi bi-person-bounding-box"></i><span>Field Visit Assistance</span></li>
                        <li><i className="bi bi-camera"></i><span>Professional Photoshoot of Profile</span></li>
                    </ul>
                </div>
            </div>
            <div className='offerrightlist'>
                <div className='offerBlock'>
                    <div className='percentageBlockWrap'>
                        <h3>Basic</h3>
                        <div className='percentageBlock'>
                            <SemiCircleProgress
                                percentage={80} 
                                size={{
                                width: 60,
                                height: 60,
                                }}
                                strokeWidth={10}
                                strokeColor="#36C991"
                                hasBackground={true}
                            />
                            <span>Listing Visibility</span>
                        </div>
                    </div>

                    <ul>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-calendar"></i><span className=''>Plan Validity</span></div> 30 Days</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-buildings-fill"></i><span className=''>Position of your property in search result</span></div> Medium Slot</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-people-fill"></i><span className=''>Matching Buyers</span></div> <i className="bi bi-x-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-headset"></i><span className=''>Relationship Manager Assistance</span></div><i className="bi bi-x-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-person-bounding-box"></i><span>Field Visit Assistance</span></div><i className="bi bi-x-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-camera"></i><span>Professional Photoshoot of Property</span></div><i className="bi bi-check-circle-fill"></i></li>
                    </ul>

                    <div className='priceBlock'>
                        <div className='price'>₹ 2999</div>
                        <button className='btn'>Upgrade</button>
                    </div>

                </div>

                <div className='offerBlock popular'>
                    <div className='popular-badge'>MOST POPULAR</div>
                    <div className='percentageBlockWrap'>
                        <h3>Premium +</h3>
                        <div className='percentageBlock'>
                            <SemiCircleProgress
                                percentage={80} 
                                size={{
                                width: 60,
                                height: 60,
                                }}
                                fontStyle={{
                                    fill: "#ffffff"
                                    }}
                                strokeWidth={10}
                                strokeColor="#36C991"
                                hasBackground={true}
                            />
                            <span>Listing Visibility</span>
                        </div>
                    </div>

                    <ul>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-calendar"></i><span className=''>Plan Validity</span></div> 120 Days</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-buildings-fill"></i><span className=''>Position of your property in search result</span></div> Medium Slot</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-people-fill"></i><span className=''>Matching Buyers</span></div> Upto 20</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-headset"></i><span className=''>Relationship Manager Assistance</span></div><i className="bi bi-x-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-person-bounding-box"></i><span>Field Visit Assistance</span></div><i className="bi bi-x-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-camera"></i><span>Professional Photoshoot of Property</span></div><i className="bi bi-check-circle-fill"></i></li>
                    </ul>

                    <div className='priceBlock'>
                        <div className='price'>₹ 7499</div>
                        <button className='btn'>Upgrade</button>
                    </div>

                </div>

                <div className='offerBlock'>
                    <div className='percentageBlockWrap'>
                        <h3>Assist</h3>
                        <div className='percentageBlock'>
                            <SemiCircleProgress
                                percentage={92} 
                                size={{
                                width: 60,
                                height: 60,
                                }}
                                strokeWidth={10}
                                strokeColor="#36C991"
                                hasBackground={true}
                            />
                            <span>Listing Visibility</span>
                        </div>
                    </div>

                    <ul>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-calendar"></i><span className=''>Plan Validity</span></div> 120 Days</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-buildings-fill"></i><span className=''>Position of your property in search result</span></div> Top Slot</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-people-fill"></i><span className=''>Matching Buyers</span></div> Upto 30</li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-headset"></i><span className=''>Relationship Manager Assistance</span></div><i className="bi bi-check-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-person-bounding-box"></i><span>Field Visit Assistance</span></div><i className="bi bi-x-circle-fill"></i></li>
                        <li><div className='d-inline-flex d-lg-none me-2'><i className="bi bi-camera"></i><span>Professional Photoshoot of Property</span></div><i className="bi bi-check-circle-fill"></i></li>
                    </ul>

                    <div className='priceBlock'>
                        <div className='price'>₹ 10999</div>
                        <button className='btn'>Upgrade</button>
                    </div>

                </div>
                
            </div>

        </div>
    </>
  )
}

export default Subscription;
